import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { ApiContext } from './ApiContext';
import { isUnprocessableEntity } from '../utils/apiErrorHandling';
import { t } from '../utils/I18n';

export const ClubContext = createContext();

export const ClubContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [club, setClub] = useState({});
  const [verifiers, setVerifiers] = useState([]);
  const [clubLoading, setClubLoading] = useState(false);
  const [verifiersLoading, setVerifiersLoading] = useState(false);

  const getClub = (id) => {
    setClubLoading(true);

    return client.get(`/clubs/${id}`).then((response) => {
      setClub({ ...response.data });

      return response;
    }).finally(() => {
      setClubLoading(false);
    });
  };

  const getVerifiers = (id) => {
    setVerifiersLoading(true);

    return client.get(`/clubs/${id}/verifiers`).then((response) => {
      setVerifiers(response.data);

      return response;
    }).finally(() => {
      setVerifiersLoading(false);
    });
  };

  const handleError = (error) => {
    if (isUnprocessableEntity(error)) {
      setClub({ ...error.response.data });
      message.error(t('globals.errorsInForm'));
    }

    throw error;
  };

  const updateClub = (values) => {
    setClubLoading(true);

    return client.put(`/clubs/${club.id}`, { data: { club: values } }).then((response) => {
      setClub({ ...response.data });
      message.success('Club updated');

      return response;
    }).catch(handleError).finally(() => {
      setClubLoading(false);
    });
  };

  const value = {
    getClub,
    club,
    updateClub,
    getVerifiers,
    verifiers,
    clubLoading,
    verifiersLoading
  };

  return (
    <ClubContext.Provider value={value}>
      {children}
    </ClubContext.Provider>
  );
};

ClubContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ClubContextProvider;
