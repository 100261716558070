import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';
import { Loader } from '../components/Loader';

export const AccountsContext = createContext();

export const AccountsContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [currentAccount, setCurrentAccount] = useState([]);
  const [claimStructure, setClaimStructure] = useState({});
  const [status, setStatus] = useState('initial');

  useEffect(() => {
    if (status !== 'initial') {
      return;
    }

    setStatus('loading');

    client.get('/accounts').then((response) => {
      setAccounts([...response.data]);
      setStatus('loaded');
      setIsLoaded(true);

      return response;
    });
  }, [status, client]);

  const switchAccount = (accountId) => {
    const account = accounts.find((a) => a.id === accountId);
    setCurrentAccount(account);
    setClaimStructure(account.claimStructure);
    return account.claimStructure;
  };

  if (!isLoaded) {
    return <Loader />;
  }

  const value = {
    claimStructure,
    switchAccount,
    accounts,
    setAccounts,
    currentAccount
  };

  return (
    <AccountsContext.Provider value={value}>
      {children}
    </AccountsContext.Provider>
  );
};

AccountsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AccountsContextProvider;
