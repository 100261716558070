import deepMapKeys from 'deep-map-keys';

export const convertObjectKeys = (data, method) => {
  let convertedData;
  if (typeof data === 'string') {
    convertedData = JSON.parse(data);
  } else {
    convertedData = data;
  }

  convertedData = deepMapKeys(convertedData, method);

  return convertedData;
};

export default convertObjectKeys;
