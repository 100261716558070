import PropTypes from 'prop-types';
import { formatPrice } from '../utils/numbers';

export const MoneyFormatter = ({ value, currency }) => (
  formatPrice(value, currency)
);

MoneyFormatter.defaultProps = {
  value: null,
  currency: null
};

MoneyFormatter.propTypes = {
  value: PropTypes.string,
  currency: PropTypes.string
};

export default MoneyFormatter;
