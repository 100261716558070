import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Cascader, Form } from 'antd';
import { AppContext } from '../../contexts/AppContext';
import { FormItemWithErrors } from './FormItemWithErrors';

export const Club = ({ model, field, updateModel, form }) => {
  const { federations, getFederationsWithClubs } = useContext(AppContext);

  useEffect(() => {
    getFederationsWithClubs();
  }, [getFederationsWithClubs]);

  // field is not the same as the one that will be updated in the model
  useEffect(() => {
    form.setFieldsValue({ fullClubPath: model.fullClubPath });
  }, [form]);

  const onChange = (value) => {
    updateModel({ clubId: value[value.length - 1] || '' });
  };

  const filter = (inputValue, path) => (
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  );

  if (!model.permissions.canUpdateClub) {
    return (
      <Form.Item label={field.label}>
        {`${_.get(model, 'federation.name')} / ${_.get(model, 'group.name')} / ${_.get(model, 'club.name')}`}
      </Form.Item>
    );
  }

  return (
    <FormItemWithErrors
      label={field.label}
      name="fullClubPath"
      errors={{ fullClubPath: model.errors && model.errors.clubId }}
    >
      <Cascader
        options={federations}
        onChange={onChange}
        placeholder="Please select"
        showSearch={{ filter }}
      />
    </FormItemWithErrors>
  );
};

Club.propTypes = {
  model: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  updateModel: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
};

export default Club;
