import moment from 'moment';
import { stringToDate } from './dates';

export const buildDynamicForm = (model, fields) => {
  const out = {};

  fields.forEach((field) => {
    if (field.type === 'date' || field.type === 'datetime') {
      out[field.key] = stringToDate(model[field.key]);
    } else if (field.type === 'money') {
      out[field.key] = model[field.key];
      out[`${field.key}Currency`] = model[`${field.key}Currency`] || 'AUD';
    } else if (field.type === 'phone') {
      out[field.key] = model[field.key];
      out[`${field.key}Country`] = model[`${field.key}Country`] || 'AU';
    } else {
      out[field.key] = model[field.key];
    }
  });

  return out;
};

export const serializeDynamicForm = (values, fields) => {
  const out = {};

  fields.forEach((field) => {
    if (field.type === 'date' && moment.isMoment(values[field.key])) {
      out[field.key] = values[field.key].format('YYYY-MM-DD');
    }
  });

  return { ...values, ...out };
};

export default {
  buildDynamicForm,
  serializeDynamicForm
};
