import { snakeCase, camelCase } from 'change-case';
import _ from 'lodash';

export const DEFAULT_PAGINATION = {
  defaultPageSize: 25,
  pageSizeOptions: [25, 50, 100],
  total: 0
};

export const updatedPaginationFromResponse = (pagination, response) => ({
  ...pagination,
  total: parseInt(response.headers['x-total'], 10),
  current: parseInt(response.headers['x-page'], 10),
  pageSize: parseInt(response.headers['x-per-page'], 10)
});

export const formatOrder = (sort) => {
  if (!sort.order) {
    return {};
  }

  return {
    sort: `${sort.order === 'descend' ? '-' : ''}${snakeCase(sort.field || sort.columnKey)}`
  };
};

export const searchParamsFromTableStatus = (pagination, filters, sorter, extra, queries) => {
  const updatedFilters = {};

  _.forIn(filters, (value, key) => {
    if (typeof value !== 'undefined' && value !== null) {
      updatedFilters[snakeCase(key)] = value;
    }
  });

  const params = {
    ...updatedFilters,
    ...queries,
    page: extra.action === 'paginate' ? pagination.current : 1,
    per_page: pagination.pageSize,
    ...formatOrder(sorter)
  };

  return params;
};

export const updatedFiltersFromParams = (params = {}) => {
  const paramsToConvert = _.omit(params, ['page', 'per_page', 'sort', 'q', 'requires_action']);
  const updatedFilters = {};

  // Converts every filter to array if param is string
  _.forIn(paramsToConvert, (value, key) => {
    if (typeof value === 'string') {
      updatedFilters[camelCase(key)] = new Array(value);
    } else {
      updatedFilters[camelCase(key)] = value;
    }
  });

  return updatedFilters;
};

export const updatedSorterFromParams = (params = {}) => {
  if (!params.sort) {
    return {};
  }

  if (params.sort.indexOf('-') === 0) {
    return { field: camelCase(params.sort.slice(1, params.sort.length)), order: 'descend' };
  }

  return { field: camelCase(params.sort), order: 'ascend' };
};

export const updatedQueriesFromParams = (params = {}) => (
  {
    q: params.q,
    due_at_from: params.due_at_from,
    due_at_until: params.due_at_until,
    requires_action: params.requires_action === 'true' || params.requires_action === true
  }
);

export default {
  DEFAULT_PAGINATION,
  updatedPaginationFromResponse,
  formatOrder,
  searchParamsFromTableStatus,
  updatedFiltersFromParams,
  updatedSorterFromParams,
  updatedQueriesFromParams
};
