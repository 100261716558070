import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TripsContext } from '../../contexts/TripsContext';
import { ClaimContext } from '../../contexts/ClaimContext';
import { SelectWithErrors } from './SelectWithErrors';

export const Trip = ({ model, field, updateModel }) => {
  const { trips, getTrips } = useContext(TripsContext);
  const { claim } = useContext(ClaimContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getTrips({ user_email: claim.user.email, page: 'all' });
    }
  }, [getTrips, loaded]);

  return (
    <SelectWithErrors
      label={field.label}
      name={field.key}
      errors={model.errors}
      updateModel={updateModel}
      showSearch
      options={trips}
      optionKey="id"
      optionValue="label"
    />
  );
};

Trip.propTypes = {
  model: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  updateModel: PropTypes.func.isRequired
};

export default Trip;
