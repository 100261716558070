import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { FormItemWithErrors } from './FormItemWithErrors';

export const TextAreaWithErrors = ({
  name,
  errors,
  label,
  placeholder,
  updateModel,
  modelName
}) => {
  let timeout;

  const onChange = (event) => {
    const { value } = event.target;

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      updateModel({ [name]: value });
    }, 500);
  };

  return (
    <FormItemWithErrors label={label} name={name} errors={errors} modelName={modelName}>
      <Input.TextArea onChange={onChange} placeholder={placeholder} />
    </FormItemWithErrors>
  );
};

TextAreaWithErrors.defaultProps = {
  errors: {},
  label: null,
  placeholder: '',
  updateModel: () => {},
  modelName: ''
};

TextAreaWithErrors.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  updateModel: PropTypes.func,
  modelName: PropTypes.string
};

export default TextAreaWithErrors;
