import React from 'react';
import PropTypes from 'prop-types';
import { DynamicFormatter } from '../formatters/DynamicFormatter';
import { ConditionalField } from './ConditionalField';
import { ClubDescription } from './ClubDescription';
import { TripDescription } from './TripDescription';

export const ConditionalDescription = ({ model, field, labelSpan }) => {
  const components = {
    club: ClubDescription,
    trip: TripDescription
  };

  if (field.type === 'component') {
    const DynamicComponent = components[field.componentType];
    return <DynamicComponent model={model} />;
  }

  return (
    <ConditionalField model={model} field={field}>
      <dt className={`ant-col ant-col-${labelSpan}`}>{field.label}</dt>
      <dd className={`ant-col ant-col-${24 - labelSpan}`}>
        <DynamicFormatter model={model} field={field} />
      </dd>
    </ConditionalField>
  );
};

ConditionalDescription.defaultProps = {
  labelSpan: 8
};

ConditionalDescription.propTypes = {
  model: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  labelSpan: PropTypes.number
};

export default ConditionalDescription;
