import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import Cookies from 'js-cookie';
import { ApiContext } from './ApiContext';
import { Loader } from '../components/Loader';
import { t } from '../utils/I18n';
import { isUnprocessableEntity } from '../utils/apiErrorHandling';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children, app }) => {
  const [user, setUser] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    client,
    setIsLoggedIn,
    setErrorMessage,
    authTokenKey,
    domain
  } = useContext(ApiContext);

  const checkAuthorized = useCallback((response) => {
    if (app === 'backoffice') {
      if (response.data.permissions.canSeeBackoffice) {
        setUser(response.data);
        setIsLoggedIn(true);
      } else {
        setErrorMessage('Invalid username or password');
        Cookies.remove(authTokenKey, { domain });
      }
    } else {
      setUser(response.data);
      setIsLoggedIn(true);
    }
  }, [setUser, setIsLoggedIn, setErrorMessage]);

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    client.get('/users/me').then(checkAuthorized).catch(() => {
      Cookies.remove(authTokenKey, { domain });
    }).finally(() => {
      setIsLoaded(true);
    });
  }, [isLoaded, client, checkAuthorized, authTokenKey, domain]);

  const loginSuccess = (response) => {
    Cookies.set(authTokenKey, response.headers.authorization, { domain });
    checkAuthorized(response);

    return response;
  };

  const loginError = (error, errorMessage = null) => {
    Cookies.remove(authTokenKey, { domain });
    setErrorMessage(errorMessage || error.response.data.message);

    throw error;
  };

  const handleUserErrors = (error) => {
    if (isUnprocessableEntity(error)) {
      setUser({ ...error.response.data });
    }

    throw error;
  };

  const login = (values) => {
    const data = { data: { user: { ...values } } };
    return client.post('/users/session', data).then(loginSuccess).catch((error) => {
      if (user && user.otpRequiredForLogin) {
        loginError(error, 'Invalid two-factor code');
      } else {
        loginError(error);
      }
    });
  };

  const preAuthenticate = (values) => {
    const data = { data: { user: { ...values } } };
    return client.post('/users/session/pre_authenticate', data).then((response) => {
      setUser(response.data);
      setErrorMessage('');

      return response;
    }).catch((error) => {
      loginError(error);

      throw error;
    });
  };

  const logout = () => (
    client.delete('/users/session').then(() => {
      Cookies.remove(authTokenKey, { domain });
      window.location = '/';
    })
  );

  const confirm = (values, confirmationToken, updateUser = true, loginAfterConfirm = false) => {
    const data = { data: { user: values, confirmationToken } };
    return client.put('/users/confirmation', data).then((response) => {
      if (loginAfterConfirm) {
        loginSuccess(response);
      }

      return response;
    }).catch((error) => {
      Cookies.remove(authTokenKey, { domain });
      setErrorMessage(error.response.data.message);

      if (updateUser && isUnprocessableEntity(error)) {
        setUser({ ...error.response.data });
      }

      throw error;
    });
  };

  const unlock = (unlockToken) => {
    const params = { params: { unlock_token: unlockToken } };
    return client.get('/users/unlock', params).catch((error) => {
      Cookies.remove(authTokenKey, { domain });
      setErrorMessage('Unlock token is invalid');

      throw error;
    });
  };

  const updateUser = (values) => (
    client.put(`/users/${user.id}`, { data: { user: values } }).then((response) => {
      setUser(response.data);
    }).catch(handleUserErrors)
  );

  const signup = (values) => (
    client.post('/users/registration', { data: { user: values } }).then((response) => {
      setUser(response.data);
    }).catch(handleUserErrors)
  );

  const recoverPassword = (email) => (
    client.post('/users/password', { data: { user: { email } } }).then(() => {
      message.success(t('devise.passwords.sendInstructions'), 10);
    }).catch(handleUserErrors)
  );

  const changePassword = (values, resetPasswordToken) => {
    const data = { data: { user: { ...values, resetPasswordToken } } };
    return client.put('/users/password', data).then(loginSuccess).catch((error) => {
      setErrorMessage(error.response.data.message);
      handleUserErrors(error);
    });
  };

  const resendConfirmation = (values) => (
    client.post('/users/confirmation', { data: { user: values } }).then(() => {
      message.success(t('devise.confirmations.sendInstructions'), 10);
    }).catch(handleUserErrors)
  );

  const resendUnlock = (values) => (
    client.post('/users/unlock', { data: { user: values } }).then(() => {
      message.success(t('devise.unlocks.sendInstructions'), 10);
    }).catch(handleUserErrors)
  );

  const updatePassword = (values) => {
    const data = { data: { user: { ...values } } };
    return client.patch('/profile/password', data).then(() => {
      setUser({ ...user, errors: {} });
    }).catch(handleUserErrors);
  };

  const getOtpQrCode = () => (
    client.get('profile/otps/qr_code')
  );

  const createOtp = (values) => (
    client.post('profile/otps', { data: { otp: { ...values } } }).then(() => {
      setUser({ ...user, otpRequiredForLogin: true });
    })
  );

  const clearUserErrors = () => {
    setUser({ ...user, errors: null });
  };

  if (!isLoaded) {
    return <Loader />;
  }

  const value = {
    user,
    login,
    logout,
    signup,
    confirm,
    unlock,
    updateUser,
    recoverPassword,
    changePassword,
    resendConfirmation,
    resendUnlock,
    updatePassword,
    getOtpQrCode,
    createOtp,
    preAuthenticate,
    clearUserErrors
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.defaultProps = {
  app: ''
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  app: PropTypes.string
};

export default AuthContextProvider;
