import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ClubFormatter } from './ClubFormatter';
import { MoneyFormatter } from './MoneyFormatter';
import { DateFormatter } from './DateFormatter';
import { DateTimeFormatter } from './DateTimeFormatter';
import { SelectFormatter } from './SelectFormatter';
import { FilesFormatter } from './FilesFormatter';

export const DynamicFormatter = ({ model, field }) => {
  const components = {
    club: ClubFormatter
  };

  const fieldValue = () => {
    if (!field.path) {
      return model[field.key];
    }

    return _.get(model, field.path);
  };

  const currencyValue = () => (model[`${field.key}Currency`]);

  const phoneNumerWithCode = () => (model[`${field.key}WithCode`]);

  if (field.type === 'money') {
    return (
      <MoneyFormatter value={fieldValue()} currency={currencyValue()} />
    );
  }

  if (field.type === 'date') {
    return (
      <DateFormatter value={fieldValue()} />
    );
  }

  if (field.type === 'datetime') {
    return (
      <DateTimeFormatter value={fieldValue()} />
    );
  }

  if (field.type === 'select') {
    return (
      <SelectFormatter value={fieldValue()} field={field} />
    );
  }

  if (field.type === 'files') {
    return (
      <FilesFormatter documents={fieldValue()} field={field} />
    );
  }

  if (field.type === 'phone') {
    return (
      phoneNumerWithCode() || fieldValue()
    );
  }

  if (field.type === 'component') {
    const DynamicComponent = components[field.componentType];
    return <DynamicComponent model={model} />;
  }

  return (
    <Fragment>
      { fieldValue() }
    </Fragment>
  );
};

DynamicFormatter.propTypes = {
  model: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired
};

export default DynamicFormatter;
