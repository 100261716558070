import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';
import {
  DEFAULT_PAGINATION,
  updatedPaginationFromResponse,
  searchParamsFromTableStatus,
  updatedSorterFromParams,
  updatedFiltersFromParams,
  updatedQueriesFromParams
} from '../utils/tables';

export const UsersContext = createContext();

export const UsersContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [sorter, setSorter] = useState({});
  const [queries, setQueries] = useState({});
  const [loading, setLoading] = useState(false);

  const getUsers = (params = {}) => {
    setLoading(true);

    return client.get('/users', { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setSorter(updatedSorterFromParams(params));
      setFilters(updatedFiltersFromParams(params));
      setQueries(updatedQueriesFromParams(params));

      setUsers([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const updateUsers = (newPagination, newFilters, newSorter, extra) => {
    const finalQueries = extra.action === 'queries' ? extra.queries : queries;
    const searchParams = searchParamsFromTableStatus(
      newPagination, newFilters, newSorter, extra, finalQueries
    );

    getUsers(searchParams);

    return searchParams;
  };

  const createDummyUser = (accountId) => {
    setLoading(true);

    return client.post('/users/dummy', { data: { user: { accountId } } }).then((response) => {
      getUsers();

      return response;
    });
  };

  const getAdmins = () => (
    getUsers({ role: ['gowgates', 'admin'], per_page: 100, status: 'active', sort: 'name' })
  );

  const value = {
    users,
    getUsers,
    createDummyUser,
    loading,
    pagination,
    filters,
    sorter,
    setFilters,
    updateUsers,
    queries,
    getAdmins
  };

  return (
    <UsersContext.Provider value={value}>
      {children}
    </UsersContext.Provider>
  );
};

UsersContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default UsersContextProvider;
