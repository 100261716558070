export const isUnauthorized = (error) => (error.response && error.response.status === 401);
export const isNotFound = (error) => (error.response && error.response.status === 404);
export const isForbidden = (error) => (error.response && error.response.status === 403);
export const isUnprocessableEntity = (error) => (error.response && error.response.status === 422);
export const isInternalServerError = (error) => (error.response && error.response.status === 500);

export default {
  isUnauthorized,
  isUnprocessableEntity,
  isNotFound,
  isForbidden,
  isInternalServerError
};
