import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { dateTimeFormat, timeFormat } from '../../utils/dates';
import { FormItemWithErrors } from './FormItemWithErrors';

const showTime = { format: timeFormat };

export const DateTimePickerWithErrors = ({ name, errors, label, updateModel, modelName }) => {
  const onChange = (_date, dateString) => {
    updateModel({ [name]: dateString });
  };

  return (
    <FormItemWithErrors label={label} name={name} errors={errors} modelName={modelName}>
      <DatePicker
        onChange={onChange}
        showTime={showTime}
        format={dateTimeFormat}
        placeholder={dateTimeFormat}
        minuteStep={5}
      />
    </FormItemWithErrors>
  );
};

DateTimePickerWithErrors.defaultProps = {
  errors: {},
  label: null,
  updateModel: () => {},
  modelName: ''
};

DateTimePickerWithErrors.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  updateModel: PropTypes.func,
  modelName: PropTypes.string
};

export default DateTimePickerWithErrors;
