import axios from 'axios';
import { Service } from 'axios-middleware';
import { snakeCase, camelCase } from 'change-case';
import { convertObjectKeys } from '../utils/object';

const railsSnake = (data) => {
  if (data === '_destroy') {
    return data;
  }

  return snakeCase(data);
};

// Register a new service middleware to work with Rails backends, where typically the JSON keys are
// sent as snake_case, that we want to convert to camelCase, since it's the default case in JS.
export const registerService = () => {
  const service = new Service(axios);

  service.register({
    onRequest(request) {
      if (request.data && !(request.data instanceof FormData)) {
        return { ...request, data: JSON.stringify(convertObjectKeys(request.data, railsSnake)) };
      }

      return request;
    },
    onSync(promise) {
      return promise;
    },
    onResponse(response) {
      if (response.data && response.headers['content-type'].indexOf('application/json') >= 0) {
        return { ...response, data: convertObjectKeys(response.data, camelCase) };
      }

      return response;
    },
    onResponseError(error) {
      const newError = error;

      if (error.response && error.response.data) {
        newError.response = {
          ...error.response,
          data: convertObjectKeys(error.response.data, camelCase)
        };
      }

      throw newError;
    }
  });
};

export default registerService;
