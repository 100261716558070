import numeral from 'numeral';

export const DEFAULT_CURRENCY = 'AUD';

const PRICE_FORMAT = '0,0.00';

export const formatPrice = (number, currency = DEFAULT_CURRENCY) => (
  `${currency || DEFAULT_CURRENCY} ${numeral(number).format(PRICE_FORMAT)}`
);

export const numberFrom = (value) => numeral(value).value();
