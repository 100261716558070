import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';
import { DEFAULT_PAGINATION, updatedPaginationFromResponse } from '../utils/tables';

export const CoversContext = createContext();

export const CoversContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [covers, setCovers] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [loading, setLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);

  const getCovers = (params = {}) => {
    setLoading(true);

    return client.get('/covers', { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setCovers([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const createCover = (cover) => {
    setCoverLoading(true);

    return client.post('covers', { data: { ...cover } }).then((response) => {
      setCovers([...covers, response.data]);

      return response;
    }).finally(() => {
      setCoverLoading(false);
    });
  };

  const value = {
    covers,
    getCovers,
    createCover,
    loading,
    pagination,
    coverLoading
  };

  return (
    <CoversContext.Provider value={value}>
      {children}
    </CoversContext.Provider>
  );
};

CoversContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CoversContextProvider;
