import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { ApiContext } from './ApiContext';
import { t } from '../utils/I18n';
import { isUnprocessableEntity } from '../utils/apiErrorHandling';

export const GroupContext = createContext();

export const GroupContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [group, setGroup] = useState({});
  const [verifiers, setVerifiers] = useState([]);
  const [groupLoading, setGroupLoading] = useState(false);
  const [verifiersLoading, setVerifiersLoading] = useState(false);

  const getGroup = (id) => {
    setGroupLoading(true);

    return client.get(`/groups/${id}`).then((response) => {
      setGroup({ ...response.data });

      return response;
    }).finally(() => {
      setGroupLoading(false);
    });
  };

  const getVerifiers = (id) => {
    setVerifiersLoading(true);

    return client.get(`/groups/${id}/verifiers`).then((response) => {
      setVerifiers(response.data);

      return response;
    }).finally(() => {
      setVerifiersLoading(false);
    });
  };

  const handleError = (error) => {
    if (isUnprocessableEntity(error)) {
      setGroup({ ...error.response.data });
      message.error(t('globals.errorsInForm'));
    }

    throw error;
  };

  const updateGroup = (values) => {
    setGroupLoading(true);

    return client.put(`/groups/${group.id}`, { data: { group: values } }).then((response) => {
      setGroup({ ...response.data });
      message.success('Group updated');

      return response;
    }).catch(handleError).finally(() => {
      setGroupLoading(false);
    });
  };

  const value = {
    getGroup,
    group,
    updateGroup,
    getVerifiers,
    verifiers,
    groupLoading,
    verifiersLoading
  };

  return (
    <GroupContext.Provider value={value}>
      {children}
    </GroupContext.Provider>
  );
};

GroupContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default GroupContextProvider;
