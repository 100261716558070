import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';

export const AppContext = createContext();

let countriesLoaded = false;
let statesLoaded = false;
let currenciesLoaded = false;
let federationsLoaded = false;
let taskTypesLoaded = false;
let gowGatesTaskTypesLoaded = false;

export const AppContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [federations, setFederations] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [gowGatesTaskTypes, setGowGatesTaskTypes] = useState([]);
  const [statistics, setStatistics] = useState({ user: {}, global: {} });
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [usersStatistics, setUsersStatistics] = useState([]);
  const [usersStatisticsLoading, setUsersStatisticsLoading] = useState(false);

  const getStates = () => {
    if (!statesLoaded) {
      statesLoaded = true;

      return client.get('/states.json').then((response) => {
        setStates([...response.data]);

        return response;
      });
    }

    return null;
  };

  const getCountries = () => {
    if (!countriesLoaded) {
      countriesLoaded = true;

      return client.get('/countries.json').then((response) => {
        setCountries([...response.data]);

        return response;
      });
    }

    return null;
  };

  const getCurrencies = () => {
    if (!currenciesLoaded) {
      currenciesLoaded = true;

      return client.get('/currencies.json').then((response) => {
        setCurrencies([...response.data]);

        return response;
      });
    }

    return null;
  };

  const getFederationsWithClubs = () => {
    if (!federationsLoaded) {
      federationsLoaded = true;

      return client.get('/federations/with_clubs').then((response) => {
        setFederations([...response.data]);

        return response;
      });
    }

    return null;
  };

  const getTaskTypes = () => {
    if (!taskTypesLoaded) {
      taskTypesLoaded = true;

      return client.get('/task_types.json').then((response) => {
        setTaskTypes([...response.data]);

        return response;
      });
    }

    return null;
  };

  const getGowGatesTaskTypes = () => {
    if (!gowGatesTaskTypesLoaded) {
      gowGatesTaskTypesLoaded = true;

      return client.get('/gowgates_task_types.json').then((response) => {
        setGowGatesTaskTypes([...response.data]);

        return response;
      });
    }

    return null;
  };

  const getStatistics = () => {
    setStatisticsLoading(true);

    return client.get('/statistics').then((response) => {
      setStatistics({ ...response.data });

      return response;
    }).finally(() => {
      setStatisticsLoading(false);
    });
  };

  const getUsersStatistics = () => {
    setUsersStatisticsLoading(true);

    return client.get('/statistics/users').then((response) => {
      setUsersStatistics([...response.data]);

      return response;
    }).finally(() => {
      setUsersStatisticsLoading(false);
    });
  };

  const value = {
    states,
    getStates,
    countries,
    getCountries,
    currencies,
    getCurrencies,
    federations,
    getFederationsWithClubs,
    taskTypes,
    getTaskTypes,
    gowGatesTaskTypes,
    getGowGatesTaskTypes,
    statistics,
    getStatistics,
    statisticsLoading,
    getUsersStatistics,
    usersStatistics,
    usersStatisticsLoading
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppContextProvider;
