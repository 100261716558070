import moment from 'moment';

export const dateFormat = 'DD-MM-YYYY';
export const timeFormat = 'hh:mm';
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

const momentFormats = {
  default: 'ddd, DD MMM YYYY HH:mm:ss',
  short: 'DD MMM YYYY hh:mm A',
  shortDate: 'DD MMM YYYY',
  time: 'hh:mm A'
};

const formatToMoment = (inputDate, format) => {
  if (!inputDate) {
    return null;
  }

  return moment(inputDate).format(momentFormats[format]);
};

export const formatDate = (inputDate, format = 'shortDate') => {
  const momentFormat = momentFormats[format] ? format : 'shortDate';

  return formatToMoment(inputDate, momentFormat);
};

export const formatDateTime = (inputDate, format = 'short') => {
  const momentFormat = momentFormats[format] ? format : 'short';

  return formatToMoment(inputDate, momentFormat);
};

export const formatTimeAgo = (inputDate) => moment(inputDate).fromNow();

export const stringToDate = (dateStr) => (dateStr ? moment(dateStr) : null);

export const toWeeksAndDays = (totalDays) => {
  if (totalDays === 0) {
    return '0 days';
  }

  const weeks = Math.floor(totalDays / 7);
  const days = totalDays % 7;

  const out = [];
  if (weeks > 0) {
    out.push(`${weeks} ${weeks === 1 ? 'week' : 'weeks'}`);
  }

  if (days > 0) {
    out.push(`${days} ${days === 1 ? 'day' : 'days'}`);
  }

  return out.join(', ');
};

export default {
  dateFormat,
  timeFormat,
  dateTimeFormat,
  formatDate,
  formatDateTime,
  formatTimeAgo,
  stringToDate,
  toWeeksAndDays
};
