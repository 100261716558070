import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { ApiContext } from './ApiContext';
import { UserContext } from './UserContext';
import { DEFAULT_PAGINATION, updatedPaginationFromResponse } from '../utils/tables';
import { isUnprocessableEntity } from '../utils/apiErrorHandling';
import { t } from '../utils/I18n';

export const UserPermissionsContext = createContext();

export const UserPermissionsContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const { user } = useContext(UserContext);
  const [permissions, setPermissions] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [loading, setLoading] = useState(false);

  const getPermissions = (params = {}) => {
    setLoading(true);

    return client.get(`users/${user.id}/permissions`, { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setPermissions([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const createPermission = (userPermission) => (
    client.post(`users/${user.id}/permissions`, { data: { userPermission } }).then((response) => {
      setPermissions([...permissions, response.data]);

      return response;
    }).catch((error) => {
      if (isUnprocessableEntity(error)) {
        message.error(t('globals.errorsInForm'));
      }

      throw error;
    })
  );

  const destroyPermission = (permissionId) => (
    client.delete(`users/${user.id}/permissions/${permissionId}`).then((response) => {
      const updatedPermissions = permissions.filter((permission) => permissionId !== permission.id);
      setPermissions(updatedPermissions);

      return response;
    })
  );

  const value = {
    user,
    permissions,
    getPermissions,
    createPermission,
    destroyPermission,
    loading,
    pagination
  };

  return (
    <UserPermissionsContext.Provider value={value}>
      {children}
    </UserPermissionsContext.Provider>
  );
};

UserPermissionsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default UserPermissionsContextProvider;
