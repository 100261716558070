import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Button, Typography } from 'antd';
import { UploadOutlined, FileTwoTone, DeleteOutlined } from '@ant-design/icons';
import { FormItemWithErrors } from './FormItemWithErrors';

export const FileUploaderWithErrors = ({ name, errors, label, form, model, modelName }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (model[name]) {
      setFileList([model[name]]);
    }
  }, [model, name, setFileList]);

  const onRemove = () => {
    setFileList([]);
  };

  const beforeUpload = (file) => {
    setFileList([file]);
    form.setFieldsValue({ ...form.getFieldsValue(), [name]: file });
    return false;
  };

  if (fileList.length > 0) {
    return (
      <FormItemWithErrors
        label={label}
        name={name}
        errors={errors}
        modelName={modelName}
      >
        <div className="file-preview">
          <FileTwoTone className="file-preview-icon" />
          <div className="file-preview-filename">
            {
              fileList[0].url ? (
                <a href={fileList[0].url} target="_blank" rel="noopener noreferrer">{fileList[0].name}</a>
              ) : (
                fileList[0].name
              )
            }
          </div>

          <div className="file-preview-delete">
            <Button type="link" danger className="pr-0" onClick={onRemove}>
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      </FormItemWithErrors>
    );
  }

  return (
    <FormItemWithErrors
      label={label}
      name={`${name}Upload`}
      errors={errors}
      modelName={modelName}
    >
      <Upload onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList} multiple={false}>
        <Button>
          <UploadOutlined />
          Select File
        </Button>
      </Upload>
      {
        errors[name] && (
          <Typography.Text type="danger">{errors[name].join(', ')}</Typography.Text>
        )
      }
    </FormItemWithErrors>
  );
};

FileUploaderWithErrors.defaultProps = {
  errors: {},
  label: null,
  modelName: ''
};

FileUploaderWithErrors.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  model: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  modelName: PropTypes.string
};

export default FileUploaderWithErrors;
