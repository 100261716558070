import axios from 'axios';
import qs from 'qs';
import { registerService } from './registerService';
import { hasFiles, objectToFormData } from './objectToFormData';

const methods = ['get', 'post', 'put', 'patch', 'delete'];

export class ApiClient {
  // Creates a method corresponding to each of the supported http verbs
  constructor(baseUrl) {
    methods.forEach((method) => {
      this[method] = (path, params = {}) => {
        const defaultHeaders = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: params.authorization || ''
        };

        const options = { ...params };
        options.method = method;
        options.url = `${baseUrl}${(path.startsWith('/') ? path : `/${path}`)}`;
        options.headers = { ...options.headers, ...defaultHeaders };
        options.paramsSerializer = (p) => (
          qs.stringify(p, {
            arrayFormat: 'brackets'
          })
        );

        if (method !== 'get') {
          if (!options.data) {
            options.data = {};
          }

          if (hasFiles(options.data)) {
            options.data = objectToFormData(options.data);
            options.headers['Content-Type'] = 'multipart/form-data';
          }
        }

        return axios(options);
      };

      return this[method];
    });

    // Register the Rails middleware that converts camel case into snake_case and vice-versa.
    registerService();
  }
}

export default ApiClient;
