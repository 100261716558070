import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import { dateFormat } from '../../utils/dates';
import { FormItemWithErrors } from './FormItemWithErrors';

export const DatePickerWithErrors = ({
  name,
  errors,
  label,
  updateModel,
  constraints,
  modelName,
  allowClear
}) => {
  const [max, setMax] = useState(null);
  const [min, setMin] = useState(null);

  useEffect(() => {
    if (constraints.max === 'now') {
      setMax(moment().endOf('day'));
    } else if (constraints.max) {
      setMax(moment(constraints.max).endOf('day'));
    }

    if (constraints.min === 'now') {
      setMin(moment().startOf('day'));
    } else if (constraints.min) {
      setMin(moment(constraints.min).startOf('day'));
    }
  }, [constraints]);

  const onChange = (_date, dateString) => {
    updateModel({ [name]: dateString });
  };

  const disabledDate = (current) => {
    if (min && max) {
      return current && (current <= min || current >= max);
    }

    if (max) {
      return current && current >= max;
    }

    if (min) {
      return current && current <= min;
    }

    return null;
  };

  return (
    <FormItemWithErrors label={label} name={name} errors={errors} modelName={modelName}>
      <DatePicker
        disabledDate={disabledDate}
        onChange={onChange}
        format={dateFormat}
        placeholder={dateFormat}
        allowClear={allowClear}
      />
    </FormItemWithErrors>
  );
};

DatePickerWithErrors.defaultProps = {
  errors: {},
  label: null,
  updateModel: () => {},
  constraints: {},
  modelName: '',
  allowClear: true
};

DatePickerWithErrors.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  updateModel: PropTypes.func,
  constraints: PropTypes.object,
  modelName: PropTypes.string,
  allowClear: PropTypes.bool
};

export default DatePickerWithErrors;
