import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export const ConditionalField = ({ model, field, excludeReadonly, children }) => {
  const isVisible = () => {
    if (!field.condition) {
      return true;
    }

    const operators = {
      eq: (leftVal, rightVal) => (leftVal === rightVal),
      neq: (leftVal, rightVal) => (leftVal !== rightVal),
      lt: (leftVal, rightVal) => (leftVal < rightVal),
      gt: (leftVal, rightVal) => (leftVal > rightVal),
      lte: (leftVal, rightVal) => (leftVal <= rightVal),
      gte: (leftVal, rightVal) => (leftVal >= rightVal),
      exist: (leftVal) => (!!leftVal),
      empty: (leftVal) => (!leftVal)
    };

    const leftVal = model[field.condition.field];
    const rightVal = field.condition.value;

    return operators[field.condition.operator](leftVal, rightVal) || false;
  };

  if (excludeReadonly && field.readonly) {
    return null;
  }

  if (!isVisible()) {
    return null;
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

ConditionalField.defaultProps = {
  excludeReadonly: false
};

ConditionalField.propTypes = {
  model: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  excludeReadonly: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default ConditionalField;
