import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { ApiContext } from './ApiContext';
import { isUnprocessableEntity } from '../utils/apiErrorHandling';
import { t } from '../utils/I18n';

export const CoverContext = createContext();

export const CoverContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [cover, setCover] = useState({});
  const [coverStructure, setCoverStructure] = useState([]);
  const [coverLoading, setCoverLoading] = useState(false);
  const [deleteFileLoading, setDeleteFileLoading] = useState([]);

  const getCover = (id) => {
    setCoverLoading(true);

    return client.get(`/covers/${id}`).then((response) => {
      setCover({ ...response.data });

      return response;
    }).finally(() => {
      setCoverLoading(false);
    });
  };

  const getCoverStructure = (accountId) => {
    setCoverLoading(true);

    return client.get(`/covers/structure/${accountId}`).then((response) => {
      setCoverStructure([...response.data]);

      return response;
    }).finally(() => {
      setCoverLoading(false);
    });
  };

  const handleError = (error) => {
    if (isUnprocessableEntity(error)) {
      setCover({ ...error.response.data });
      message.error(t('globals.errorsInForm'));
    }

    throw error;
  };

  const updateCover = (values) => {
    setCoverLoading(true);

    return client.put(`/covers/${cover.id}`, { data: { cover: values } }).then((response) => {
      setCover({ ...response.data });
      message.success('Cover updated');

      return response;
    }).catch(handleError).finally(() => {
      setCoverLoading(false);
    });
  };

  const addFile = (values) => (
    client.post(`/covers/${cover.id}/files`, { data: { cover: values } }).then((response) => {
      setCover({ ...response.data });
      message.success('File added');

      return response;
    }).catch(handleError)
  );

  const deleteFile = (id) => {
    setDeleteFileLoading([...deleteFileLoading, id]);

    return client.delete(`/covers/${cover.id}/files/${id}`).then((response) => {
      setCover({ ...response.data });
      message.success('File removed');

      return response;
    }).catch(handleError).finally(() => {
      const index = deleteFileLoading.indexOf(id);

      setDeleteFileLoading([...deleteFileLoading.splice(index, 1)]);
    });
  };

  const value = {
    getCover,
    getCoverStructure,
    cover,
    coverStructure,
    updateCover,
    addFile,
    deleteFile,
    coverLoading,
    deleteFileLoading
  };

  return (
    <CoverContext.Provider value={value}>
      {children}
    </CoverContext.Provider>
  );
};

CoverContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CoverContextProvider;
