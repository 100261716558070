import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export const If = ({ condition, children }) => {
  if (!condition) {
    return null;
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

If.defaultProps = {
  condition: false
};

If.propTypes = {
  condition: PropTypes.any,
  children: PropTypes.node.isRequired
};

export default If;
