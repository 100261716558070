import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';

export const UserAvatar = ({ user, size, className }) => {
  if (!user) {
    return null;
  }

  if (user.avatar) {
    return (
      <Avatar size={size} className={className} src={user.avatar.url} />
    );
  }

  return (
    <Avatar size={size} className={className}>
      {user.firstName[0]}
      {user.lastName[0]}
    </Avatar>
  );
};

UserAvatar.defaultProps = {
  size: '',
  className: '',
  user: null
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatar: PropTypes.object
  }),
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  className: PropTypes.string
};

export default UserAvatar;
