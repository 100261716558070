import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';
import { DEFAULT_PAGINATION, updatedPaginationFromResponse } from '../utils/tables';

export const ClubsContext = createContext();

export const ClubsContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [clubs, setClubs] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [loading, setLoading] = useState(false);
  const [clubLoading, setClubLoading] = useState(false);

  const getClubs = (params = {}) => {
    setLoading(true);

    return client.get('clubs', { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setClubs([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const createClub = (club) => {
    setClubLoading(true);

    return client.post('clubs', { data: { ...club } }).then((response) => {
      setClubs([...clubs, response.data]);

      return response;
    }).finally(() => {
      setClubLoading(false);
    });
  };

  const value = {
    clubs,
    getClubs,
    createClub,
    loading,
    pagination,
    clubLoading
  };

  return (
    <ClubsContext.Provider value={value}>
      {children}
    </ClubsContext.Provider>
  );
};

ClubsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ClubsContextProvider;
