import _ from 'lodash';
import { camelCase } from 'change-case';

const regex = /%\{(\w+)\}/g;

let translations = {};

const notFound = (key) => `translation missing: ${key}`;

export const t = (key, options = {}) => {
  // Converts the key to camel case to avoid doing it everywhere
  const camelKey = key.split('.').map((node) => camelCase(node)).join('.');

  let translation = _.get(translations, camelKey, notFound(camelKey));

  if (_.isPlainObject(translation)) {
    return translation;
  }

  const matches = translation.match(regex);

  if (matches) {
    matches.forEach((match) => {
      const keyword = match.replace('%{', '').replace('}', '');
      translation = translation.replace(match, options[keyword]);
    });
  }

  return translation;
};

export const setTranslations = (newTranslations) => {
  translations = newTranslations;
};

export default { t, setTranslations };
