import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { ApiClient } from '../api/client';

export const ApiContext = createContext();

export const ApiContextProvider = ({ children, authTokenKey, domain }) => {
  const client = new ApiClient(process.env.REACT_APP_BACKEND_HOST, authTokenKey);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [redirectPath, setRedirectPath] = useState('/');
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [hasError, setHasError] = useState(false);

  const getAuthorization = () => (Cookies.get(authTokenKey) || '');

  const optionsWithAuthorization = (options) => ({ ...options, authorization: getAuthorization() });

  const handleError = (error) => {
    if (!error.response) {
      setHasError(true);

      throw error;
    }

    if (error.response.status === 500) {
      setHasError(true);
    }

    if (error.response.status === 401) {
      setIsLoggedIn(false);
    }

    if (error.response.status === 403) {
      setIsAuthorized(false);
    }

    if (error.response.status === 404) {
      setIsAuthorized(false);
    }

    throw error;
  };

  const get = (url, options = {}) => (
    client.get(url, optionsWithAuthorization(options)).catch(handleError)
  );

  const post = (url, options = {}) => (
    client.post(url, optionsWithAuthorization(options)).catch(handleError)
  );

  const put = (url, options = {}) => (
    client.put(url, optionsWithAuthorization(options)).catch(handleError)
  );

  const patch = (url, options = {}) => (
    client.patch(url, optionsWithAuthorization(options)).catch(handleError)
  );

  const deleteRequest = (url, options = {}) => (
    client.delete(url, optionsWithAuthorization(options)).catch(handleError)
  );

  const value = {
    client: { get, post, put, patch, delete: deleteRequest },
    errorMessage,
    setErrorMessage,
    isLoggedIn,
    setIsLoggedIn,
    redirectPath,
    setRedirectPath,
    isAuthorized,
    setIsAuthorized,
    hasError,
    setHasError,
    authTokenKey,
    domain
  };

  return (
    <ApiContext.Provider value={value}>
      {children}
    </ApiContext.Provider>
  );
};

ApiContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  authTokenKey: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired
};

export default ApiContextProvider;
