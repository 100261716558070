import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';

const ExistingFile = ({ document, setDocument }) => {
  const deleteFile = () => {
    setDocument({ ...document, _destroy: true });
  };

  // eslint-disable-next-line no-underscore-dangle
  if (document._destroy) {
    return null;
  }

  return (
    <div className="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-text">
      <div className="ant-upload-list-item-info">
        <span>
          <div className="ant-upload-text-icon">
            <PaperClipOutlined className="anticon" />
          </div>
          <span className="ant-upload-list-item-name ant-upload-list-item-name-icon-count-1" title={document.name}>
            {document.file.name}
          </span>
          <span className="ant-upload-list-item-card-actions ">
            <div
              role="button"
              onClick={deleteFile}
              onKeyUp={deleteFile}
              tabIndex={-1}
            >
              <DeleteOutlined />
            </div>
          </span>
        </span>
      </div>
    </div>
  );
};

ExistingFile.propTypes = {
  document: PropTypes.object.isRequired,
  setDocument: PropTypes.func.isRequired
};

export default ExistingFile;
