import PropTypes from 'prop-types';

export const SelectFormatter = ({ value, field }) => {
  const option = field.options.find((o) => o.key === value);

  if (option) {
    return option.value;
  }

  return value;
};

SelectFormatter.defaultProps = {
  value: null
};

SelectFormatter.propTypes = {
  value: PropTypes.any,
  field: PropTypes.object.isRequired
};

export default SelectFormatter;
