import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';
import { DEFAULT_PAGINATION, updatedPaginationFromResponse } from '../utils/tables';

export const GroupsContext = createContext();

export const GroupsContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [groups, setGroups] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [loading, setLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);

  const getGroups = (params = {}) => {
    setLoading(true);

    return client.get('groups', { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setGroups([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const createGroup = (group) => {
    setGroupLoading(true);

    return client.post('groups', { data: { ...group } }).then((response) => {
      setGroups([...groups, response.data]);

      return response;
    }).finally(() => {
      setGroupLoading(false);
    });
  };

  const value = {
    groups,
    getGroups,
    createGroup,
    loading,
    pagination,
    groupLoading
  };

  return (
    <GroupsContext.Provider value={value}>
      {children}
    </GroupsContext.Provider>
  );
};

GroupsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default GroupsContextProvider;
