import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { message } from 'antd';
import { ApiContext } from './ApiContext';
import { CurrentAccountContext } from './CurrentAccountContext';
import { isUnprocessableEntity } from '../utils/apiErrorHandling';
import { t } from '../utils/I18n';

export const ClaimContext = createContext();

export const ClaimContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [claim, setClaim] = useState({});
  const [isEditing, setEditing] = useState(false);
  const [isClaimLoading, setClaimLoading] = useState(false);
  const [suggestedClubs, setSuggestedClubs] = useState([]);
  const [documents, setDocuments] = useState({});
  const [documentsLoaded, setDocumentsLoaded] = useState(false);
  const [verification, setVerification] = useState({});
  const [verificationLoaded, setVerificationLoaded] = useState(false);
  const [history, setHistory] = useState([]);
  const [historyLoaded, setHistoryLoaded] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentsLoaded, setCommentsLoaded] = useState(false);
  const [notes, setNotes] = useState([]);
  const [notesLoaded, setNotesLoaded] = useState(false);
  const [cover, setCover] = useState([]);
  const [coverLoaded, setCoverLoaded] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [tasksLoaded, setTasksLoaded] = useState(false);
  const [taskLoading, setTaskLoading] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false);

  const sectionBaseUrl = (section) => `/claims/${claim.id}/sections/${section.id}`;
  const itemBaseUrl = (item) => `/claims/${claim.id}/sections/${item.sectionId}/items/${item.id}`;

  const handleError = (error) => {
    if (isUnprocessableEntity(error)) {
      setClaim({ ...error.response.data });
      message.error(t('globals.errorsInForm'));
    }

    throw error;
  };

  const updatedItemsFromResponse = (response, sectionKey) => (
    claim[sectionKey].items.map((item) => {
      if (item.id === response.data.id) {
        return { ...item, ...response.data };
      }

      return item;
    })
  );

  const getClaim = (id) => {
    setClaimLoading(true);

    return client.get(`/claims/${id}`).then((response) => {
      setClaim({ ...response.data });
      setClaimLoading(false);

      return response;
    });
  };

  const createClaim = (params = {}, search = {}) => {
    setClaimLoading(true);

    return client.post(`/claims${search}`, { params }).then((response) => {
      setClaim({ ...response.data });
      setClaimLoading(false);

      return response;
    });
  };

  const updateClaim = (values) => (
    client.put(`/claims/${claim.id}`, { data: { claim: values } }).then((response) => {
      // Clears errors in form

      const errors = { ...claim.errors };
      _.forIn(values, (_value, key) => {
        errors[key] = null;
      });

      setClaim({ ...claim, ...values, errors });

      return response;
    }).catch(handleError)
  );

  const updateUser = (values) => (
    client.put(`/users/${claim.user.id}`, { data: { user: values } }).then((response) => {
      setClaim({ ...claim, user: response.data });

      return response;
    }).catch((error) => {
      if (isUnprocessableEntity(error)) {
        setClaim({ ...claim, user: { ...error.response.data } });
        message.error(t('globals.errorsInForm'));
      }

      throw error;
    })
  );

  const getHistory = () => (
    client.get(`/claims/${claim.id}/histories`).then((response) => {
      setHistory([...response.data]);
      setHistoryLoaded(true);

      return response;
    })
  );

  const getHistoryIfNeeded = (response) => {
    if (claim.status !== response.data.status && response.data.permissions.show) {
      getHistory();
    }
  };

  const sendAction = (action, params = null, updateClaimOnError = true, showMessage = true) => {
    const actionParams = params ? { data: { claim: params } } : {};

    return (
      client.patch(`/claims/${claim.id}/action/${action}`, actionParams).then((response) => {
        getHistoryIfNeeded(response);

        setClaim({ ...response.data });

        return response;
      }).catch((error) => {
        // TODO: Maybe, by default we never update the claim on errors.
        //       probably just setting the errors is enough
        if (isUnprocessableEntity(error)) {
          if (updateClaimOnError) {
            setClaim({ ...error.response.data });
          } else {
            setClaim({ ...claim, errors: error.response.data.errors });
          }
          if (showMessage) {
            message.error(t('globals.errorsInForm'));
          }
        }

        throw error;
      })
    );
  };

  const sendItemAction = (item, action) => (
    client.patch(`${itemBaseUrl(item)}/action/${action}`).then((response) => {
      getHistoryIfNeeded(response);

      setClaim({ ...response.data });

      return response;
    })
  );

  const sendItemBulkAction = (action, params = {}) => (
    client.patch(`/claims/${claim.id}/items/action/${action}`, { data: params }).then((response) => {
      getHistoryIfNeeded(response);

      setClaim({ ...response.data });

      return response;
    }).catch((error) => {
      if (isUnprocessableEntity(error)) {
        setClaim({ ...error.response.data });
      }

      throw error;
    })
  );

  // TODO: can be improved
  const addSection = (section) => (
    client.post(`/claims/${claim.id}/sections`, { data: { section } }).then((response) => {
      setClaim({ ...response.data });

      return response;
    })
  );

  // TODO: can be improved
  const destroySection = (section) => (
    client.delete(sectionBaseUrl(section)).then((response) => {
      setClaim({ ...response.data });

      return response;
    })
  );

  // TODO: can be improved - not used
  const updateSection = (section) => (
    client.put(sectionBaseUrl(section), { data: { section } }).then((response) => {
      setClaim({ ...response.data });

      return response;
    })
  );

  const addItem = (item, sectionKey) => (
    client.post(`/claims/${claim.id}/sections/${item.sectionId}/items`, { data: { item } }).then((response) => {
      setClaim({
        ...claim,
        [sectionKey]: {
          ...claim[sectionKey],
          items: [...claim[sectionKey].items, response.data]
        }
      });

      return response;
    })
  );

  const updateItem = (values, sectionKey) => (
    client.put(itemBaseUrl(values), { data: { item: values } }).then((response) => {
      setClaim({
        ...claim,
        [sectionKey]: {
          ...claim[sectionKey],
          items: updatedItemsFromResponse(response, sectionKey)
        }
      });

      return response;
    })
  );

  const deleteItem = (item, sectionKey) => (
    client.delete(itemBaseUrl(item)).then((response) => {
      setClaim({
        ...claim,
        [sectionKey]: {
          ...claim[sectionKey],
          items: claim[sectionKey].items.filter((i) => item.id !== i.id)
        }
      });

      return response;
    })
  );

  const changeItem = (itemId, thingsToDo) => {
    const updatedClaim = { ...claim };
    let continueLoop = true;

    // `every` is like forEach but breaks loop if returning a false value
    claimStructure.sections.every((sectionStructure) => {
      // section exists?
      if (claim[sectionStructure.key]) {
        updatedClaim[sectionStructure.key].items.every((item) => {
          if (item.id === parseInt(itemId, 10)) {
            thingsToDo(item);
            continueLoop = false;
          }

          return continueLoop;
        });
      }

      return continueLoop;
    });

    return updatedClaim;
  };

  const addDocument = (document) => (
    client.post('documents', { data: { document } }).then((response) => {
      if (document.documentableType === 'Item') {
        setClaim(changeItem(document.documentableId, (item) => {
          // eslint-disable-next-line no-param-reassign
          item.documents = [...item.documents, response.data];
        }));
      } else {
        setDocuments({ ...documents, documents: [...(documents.documents || []), response.data] });
      }

      return response;
    })
  );

  const deleteDocument = (document) => (
    client.delete(`documents/${document.uid}`).then((response) => {
      if (document.documentableType === 'Item') {
        setClaim(changeItem(document.documentableId, (item) => {
          // eslint-disable-next-line no-param-reassign
          item.documents = item.documents.filter((d) => document.id !== d.id);
        }));
      } else {
        setDocuments({
          ...documents,
          documents: documents.documents.filter((d) => document.id !== d.id)
        });
      }

      return response;
    })
  );

  const saveSection = (section) => {
    setSectionLoading(true);

    if (section.id) {
      return updateSection(section).finally(() => {
        setSectionLoading(false);
      });
    }

    return addSection(section).finally(() => {
      setSectionLoading(false);
    });
  };

  const saveItem = (item, sectionKey = '') => (
    item.id ? updateItem(item, sectionKey) : addItem(item, sectionKey)
  );

  const addComment = (comment) => (
    client.post('/comments', { data: { comment } }).then((response) => {
      setComments([...comments, response.data]);

      return response;
    })
  );

  const deleteComment = (comment) => {
    setComments(comments.filter((c) => comment.id !== c.id));

    return client.delete(`/comments/${comment.id}`);
  };

  const addNote = (note) => (
    client.post('/notes', { data: { note } }).then((response) => {
      setNotes([...notes, response.data]);

      return response;
    })
  );

  const deleteNote = (note) => {
    setNotes(notes.filter((c) => note.id !== c.id));

    return client.delete(`/notes/${note.id}`);
  };

  // not in frontoffice
  const startEdit = () => {
    setEditing(true);
  };

  // not in frontoffice
  const stopEdit = () => {
    setEditing(false);
  };

  // not in frontoffice
  const getSuggestedClubs = () => (
    client.get(`/claims/${claim.id}/suggested_clubs`).then((response) => {
      setSuggestedClubs([...response.data]);

      return response;
    })
  );

  const confirmDocument = (kind, documentId) => (
    client.patch(`/documents/${documentId}/confirm`).then((response) => {
      setDocuments({
        ...documents,
        [kind]: documents[kind].map((doc) => {
          if (doc.id === documentId) {
            return { ...doc, confirmed: true };
          }

          return doc;
        })
      });

      return response;
    })
  );

  const assignToMe = () => (
    client.patch(`/claims/${claim.id}/assign`).then((response) => {
      setClaim({ ...response.data });

      return response;
    })
  );

  const assignTo = (userId) => (
    client.patch(`/claims/${claim.id}/assign/${userId}`).then((response) => {
      setClaim({ ...response.data });

      return response;
    })
  );

  const getDocuments = () => (
    client.get(`/claims/${claim.id}/documents`).then((response) => {
      setDocuments({ ...response.data });
      setDocumentsLoaded(true);

      return response;
    })
  );

  const getVerification = () => (
    client.get(`/claims/${claim.id}/verification`).then((response) => {
      setVerification({ ...response.data });
      setVerificationLoaded(true);

      return response;
    })
  );

  const getComments = () => (
    client.get(`/claims/${claim.id}/comments`).then((response) => {
      setComments([...response.data]);
      setCommentsLoaded(true);

      return response;
    })
  );

  const getNotes = () => (
    client.get(`/claims/${claim.id}/notes`).then((response) => {
      setNotes([...response.data]);
      setNotesLoaded(true);

      return response;
    })
  );

  const getTasks = () => (
    client.get(`/claims/${claim.id}/tasks`).then((response) => {
      setTasks([...response.data]);
      setTasksLoaded(true);

      return response;
    })
  );

  const createTask = (task) => {
    setTaskLoading(true);

    return client.post(`/claims/${claim.id}/tasks`, { data: { ...task } }).then((response) => {
      setTasks([...tasks, response.data]);

      return response;
    }).finally(() => {
      setTaskLoading(false);
    });
  };

  const getItem = (item, sectionName) => {
    if (item.loaded) {
      return new Promise((resolve) => resolve({ data: item }));
    }

    return client.get(`/claims/${claim.id}/items/${item.id}`).then((response) => {
      const items = claim[sectionName].items.map((i) => {
        if (i.id === item.id) {
          return { ...response.data, loaded: true };
        }

        return i;
      });

      setClaim({ ...claim, [sectionName]: { ...claim[sectionName], items } });

      return response;
    });
  };

  const getCover = () => (
    client.get(`/claims/${claim.id}/cover`).then((response) => {
      setCover({ ...response.data });
      setCoverLoaded(true);

      return response;
    })
  );

  const value = {
    claim,
    documents,
    history,
    verification,
    comments,
    notes,
    cover,
    setClaim,
    updateClaim,
    sendAction,
    saveItem,
    deleteItem,
    addDocument,
    deleteDocument,
    saveSection,
    addComment,
    deleteComment,
    destroySection,
    getClaim,
    isClaimLoading,
    createClaim,
    addNote,
    deleteNote,
    startEdit,
    stopEdit,
    getSuggestedClubs,
    confirmDocument,
    updateUser,
    sendItemAction,
    sendItemBulkAction,
    suggestedClubs,
    assignToMe,
    assignTo,
    getHistory,
    historyLoaded,
    getItem,
    getDocuments,
    documentsLoaded,
    getVerification,
    verificationLoaded,
    getComments,
    commentsLoaded,
    getNotes,
    notesLoaded,
    getCover,
    coverLoaded,
    getTasks,
    setTasks,
    tasks,
    tasksLoaded,
    isEditing,
    createTask,
    taskLoading,
    sectionLoading
  };

  return (
    <ClaimContext.Provider value={value}>
      {children}
    </ClaimContext.Provider>
  );
};

ClaimContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ClaimContextProvider;
