import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { FormItemWithErrors } from './FormItemWithErrors';
import { QUILL_MODULES } from '../../utils/constants';

// const uniqueId = `${Math.random()}`.replace('0.', '');

// const CustomToolbar = () => (
//   <div id={`toolbar-${uniqueId}`}>
//     <span className="ql-formats">
//       <button type="button" role="menu" className="ql-bold" />
//       <button type="button" role="menu" className="ql-italic" />
//       <button type="button" role="menu" className="ql-underline" />
//     </span>
//     <span className="ql-formats">
//       <button type="button" role="menu" className="ql-list" value="ordered" />
//       <button type="button" role="menu" className="ql-list" value="bullet" />
//     </span>
//   </div>
// );

export const QuillWithErrors = ({ name, errors, label, placeholder, updateModel, modelName }) => {
  // const [editorHtml, setEditorHtml] = useState('');
  let timeout;

  const onChange = (value) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      updateModel({ [name]: value });
    }, 500);
  };

  // const modules = {
  //   toolbar: {
  //     container: `#toolbar-${uniqueId}`
  //   }
  // };

  return (
    <FormItemWithErrors label={label} name={name} errors={errors} modelName={modelName}>
      {/* <ReactQuill onChange={onChange} modules={modules} /> */}

      {/* <CustomToolbar /> */}

      <ReactQuill
        theme="snow"
        onChange={onChange}
        placeholder={placeholder}
        modules={QUILL_MODULES}
        formats={['bold', 'italic', 'underline', 'list']}
      />
      {/* <div className="text-editor-with-attachments">
      </div> */}
    </FormItemWithErrors>
  );
};

QuillWithErrors.defaultProps = {
  errors: {},
  label: null,
  placeholder: '',
  updateModel: () => {},
  modelName: ''
};

QuillWithErrors.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  updateModel: PropTypes.func,
  modelName: PropTypes.string
};

export default QuillWithErrors;
