import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { t } from '../../utils/I18n';

export const FormItemWithErrors = ({ name, errors, label, modelName, children }) => {
  const [hasError, setHasError] = useState(false);
  const [helpText, setHelpText] = useState('');
  const [finalLabel, setFinalLabel] = useState(label);

  useEffect(() => {
    if (modelName && (label === undefined || label === null)) {
      setFinalLabel(t(`activerecord.attributes.${modelName}.${name}`));
    }
  }, [modelName, label, name]);

  // TODO: kind of hack. Only works if the attachment is named `file`
  useEffect(() => {
    setHasError(errors && (errors[name] || errors[`${name}File`]));
  }, [errors, name]);

  // TODO: kind of hack. Only works if the attachment is named `file`
  useEffect(() => {
    if (hasError) {
      setHelpText(errors && [...(errors[name] || []), ...(errors[`${name}File`] || [])]);
    }
  }, [hasError, errors, name]);

  if (hasError) {
    return (
      <Form.Item label={finalLabel} name={name} validateStatus="error" help={helpText}>
        { children }
      </Form.Item>
    );
  }

  return (
    <Form.Item label={finalLabel} name={name}>
      { children }
    </Form.Item>
  );
};

FormItemWithErrors.defaultProps = {
  errors: {},
  label: null,
  modelName: ''
};

FormItemWithErrors.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  modelName: PropTypes.string
};

export default FormItemWithErrors;
