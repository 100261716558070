import React from 'react';
import PropTypes from 'prop-types';
import {
  FileExcelTwoTone,
  FileImageTwoTone,
  FileTwoTone,
  FilePdfTwoTone,
  FilePptTwoTone,
  FileTextTwoTone,
  FileWordTwoTone,
  FileZipTwoTone
} from '@ant-design/icons';

const fileTypes = {
  'image/apng': FileImageTwoTone,
  'image/bmp': FileImageTwoTone,
  'image/gif': FileImageTwoTone,
  'image/x-icon': FileImageTwoTone,
  'image/jpeg': FileImageTwoTone,
  'image/png': FileImageTwoTone,
  'image/svg+xml': FileImageTwoTone,
  'image/tiff': FileImageTwoTone,
  'image/webp': FileImageTwoTone,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': FileExcelTwoTone,
  'application/vnd.ms-excel': FileExcelTwoTone,
  'text/csv': FileExcelTwoTone,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': FileWordTwoTone,
  'application/msword': FileWordTwoTone,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': FilePptTwoTone,
  'application/vnd.ms-powerpoint': FilePptTwoTone,
  'application/pdf': FilePdfTwoTone,
  'application/zip': FileZipTwoTone,
  'application/vnd.rar': FileZipTwoTone,
  'application/x-tar': FileZipTwoTone,
  'application/gzip': FileZipTwoTone,
  'text/plain': FileTextTwoTone,
  'application/rtf': FileTextTwoTone
};

export const FileIcon = ({ type, className }) => {
  const finalClassName = `file-icon ${className}`;

  if (fileTypes[type]) {
    const Component = fileTypes[type];

    return (
      <Component className={finalClassName} />
    );
  }

  return (
    <FileTwoTone className={finalClassName} />
  );
};

FileIcon.defaultProps = {
  className: null
};

FileIcon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default FileIcon;
