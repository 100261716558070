import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { ApiContext } from './ApiContext';
import {
  DEFAULT_PAGINATION,
  updatedPaginationFromResponse,
  searchParamsFromTableStatus,
  updatedSorterFromParams,
  updatedFiltersFromParams,
  updatedQueriesFromParams
} from '../utils/tables';

export const ClaimsContext = createContext();

export const ClaimsContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [claims, setClaims] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [sorter, setSorter] = useState({});
  const [queries, setQueries] = useState({});
  const [loading, setLoading] = useState(false);

  const getClaims = (params = {}) => {
    setLoading(true);

    return client.get('/claims', { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setSorter(updatedSorterFromParams(params));
      setFilters(updatedFiltersFromParams(params));
      setQueries(updatedQueriesFromParams(params));

      setClaims([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const getAllClaims = (params = {}) => {
    setLoading(true);

    return client.get('/claims/all', { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setSorter(updatedSorterFromParams(params));
      setFilters(updatedFiltersFromParams(params));
      setQueries(updatedQueriesFromParams(params));

      setClaims([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const updateClaims = (newPagination, newFilters, newSorter, extra) => {
    const finalQueries = extra.queries ? extra.queries : queries;
    const searchParams = searchParamsFromTableStatus(
      newPagination, newFilters, newSorter, extra, finalQueries
    );

    getClaims(searchParams);

    return searchParams;
  };

  const updateAllClaims = (newPagination, newFilters, newSorter, extra) => {
    const finalQueries = extra.queries ? extra.queries : queries;
    const searchParams = searchParamsFromTableStatus(
      newPagination, newFilters, newSorter, extra, finalQueries
    );

    getAllClaims(searchParams);

    return searchParams;
  };

  const createClaim = (accountId) => {
    const data = { claim: { accountId } };
    return client.post('/claims/backoffice_create', { data });
  };

  const destroyClaim = (id) => (
    client.delete(`/claims/${id}`).then((response) => {
      setClaims(claims.filter((c) => id !== c.id));

      return response;
    })
  );

  const updateClaimsLocally = (data) => {
    const updatedClaims = claims.map((claim) => {
      const responseClaim = data.find((c) => c.id === claim.id);

      if (responseClaim) {
        return { ...claim, ...responseClaim };
      }

      return claim;
    });

    setClaims(updatedClaims);
  };

  const assignToMe = (claimId) => (
    client.patch('/claims/assign', { data: { claimId } }).then((response) => {
      updateClaimsLocally(response.data);

      if (response.data[0]) {
        message.success('The claim was reassigned to you');
      }

      return response;
    })
  );

  const assignTo = (claimId, assigneeId) => (
    client.patch(`/claims/assign/${assigneeId}`, { data: { claimId } }).then((response) => {
      updateClaimsLocally(response.data);

      if (response.data[0]) {
        if (response.data[0].assignee) {
          message.success(`The claim was reassigned to ${response.data[0].assignee.name}`);
        } else {
          message.success('The assignee was removed for selected claims');
        }
      }

      return response;
    })
  );

  const value = {
    claims,
    getClaims,
    getAllClaims,
    updateClaims,
    updateAllClaims,
    loading,
    pagination,
    filters,
    sorter,
    queries,
    setFilters,
    assignToMe,
    assignTo,
    createClaim,
    destroyClaim
  };

  return (
    <ClaimsContext.Provider value={value}>
      {children}
    </ClaimsContext.Provider>
  );
};

ClaimsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ClaimsContextProvider;
