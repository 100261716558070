import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { ApiContext } from './ApiContext';
import { AccountsContext } from './AccountsContext';
import { t } from '../utils/I18n';
import { isUnprocessableEntity } from '../utils/apiErrorHandling';

export const AccountContext = createContext();

export const AccountContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const { accounts, setAccounts } = useContext(AccountsContext);
  const [account, setAccount] = useState({});
  const [verifiers, setVerifiers] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false);
  const [verifiersLoading, setVerifiersLoading] = useState(false);

  const getAccount = (slug) => {
    setAccount(accounts.find((a) => a.slug === (slug)));
  };

  const getVerifiers = (id) => {
    setVerifiersLoading(true);

    return client.get(`/accounts/${id}/verifiers`).then((response) => {
      setVerifiers(response.data);

      return response;
    }).finally(() => {
      setVerifiersLoading(false);
    });
  };

  const handleError = (error) => {
    if (isUnprocessableEntity(error)) {
      setAccount({ ...error.response.data });
      message.error(t('globals.errorsInForm'));
    }

    throw error;
  };

  const updateAccount = (values) => {
    setAccountLoading(true);

    return client.put(`/accounts/${account.id}`, { data: { account: values } }).then((response) => {
      const updatedAccounts = accounts.map((a) => {
        if (a.id === account.id) {
          return response.data;
        }

        return a;
      });

      setAccounts(updatedAccounts);

      return response;
    }).catch(handleError).finally(() => {
      setAccountLoading(false);
    });
  };

  const value = {
    getAccount,
    account,
    updateAccount,
    getVerifiers,
    verifiers,
    accountLoading,
    verifiersLoading
  };

  return (
    <AccountContext.Provider value={value}>
      {children}
    </AccountContext.Provider>
  );
};

AccountContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AccountContextProvider;
