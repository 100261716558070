export const QUILL_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }]
  ]
};

export const ITEM_STATUSES = {
  submitted: 'submitted',
  infoMissing: 'info_missing',
  inInsurer: 'in_insurer',
  paid: 'paid',
  declined: 'declined'
};

export const CLAIM_OUTCOMES = {
  undefined: 'undefined',
  approved: 'approved',
  declined: 'declined'
};

export const ALLOWED_MIME_TYPES = [
  'image/*',
  'application/pdf',
  'application/zip',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/plain',
  'application/rtf',
  'audio/mp4'
];

export default {
  QUILL_MODULES,
  CLAIM_OUTCOMES,
  ITEM_STATUSES,
  ALLOWED_MIME_TYPES
};
