import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export const FilesFormatter = ({ documents }) => (
  <Fragment>
    {
      documents.map((document) => (
        <p key={document.id}>
          <a href={document.file.url} target="_blank" rel="noopener noreferrer">
            {document.file.name}
          </a>
        </p>
      ))
    }
  </Fragment>
);

FilesFormatter.defaultProps = {
  documents: []
};

FilesFormatter.propTypes = {
  documents: PropTypes.array
};

export default FilesFormatter;
