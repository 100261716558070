import PropTypes from 'prop-types';
import { t } from '../utils/I18n';

export const Translate = ({ id, options }) => {
  if (!id) {
    return null;
  }

  return t(id, options);
};

Translate.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.object
};

export default Translate;
