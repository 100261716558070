import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';

export const ExcelReportsContext = createContext();

export const ExcelReportsContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [reportsLoading, setReportsLoading] = useState(false);

  const generate = (params) => {
    setReportsLoading(true);

    return client.get('/excel_reports', { params }).finally(() => {
      setReportsLoading(false);
    });
  };

  const value = {
    generate,
    reportsLoading
  };

  return (
    <ExcelReportsContext.Provider value={value}>
      {children}
    </ExcelReportsContext.Provider>
  );
};

ExcelReportsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ExcelReportsContextProvider;
