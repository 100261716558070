import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';

export const SecureDownloadContext = createContext();

export const SecureDownloadContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [secureDownload, setSecureDownload] = useState({});
  const [secureDownloadLoading, setSecureDownloadLoading] = useState(false);

  const getSecureDownload = (uid) => {
    setSecureDownloadLoading(true);

    return client.get(`/secure_downloads/${uid}`).then((response) => {
      setSecureDownload({ ...response.data });

      return response;
    }).finally(() => {
      setSecureDownloadLoading(false);
    });
  };

  const value = {
    getSecureDownload,
    secureDownload,
    secureDownloadLoading
  };

  return (
    <SecureDownloadContext.Provider value={value}>
      {children}
    </SecureDownloadContext.Provider>
  );
};

SecureDownloadContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SecureDownloadContextProvider;
