import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { ApiContext } from './ApiContext';
import { ClaimContext } from './ClaimContext';
import {
  DEFAULT_PAGINATION,
  updatedPaginationFromResponse,
  searchParamsFromTableStatus,
  updatedSorterFromParams,
  updatedFiltersFromParams,
  updatedQueriesFromParams
} from '../utils/tables';

export const TasksContext = createContext();

export const TasksContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const { tasks: claimTasks, setTasks: setClaimTasks } = (useContext(ClaimContext) || {});
  const [tasks, setTasks] = useState([]);
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});
  const [queries, setQueries] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [loading, setLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [taskAnalytics, setTaskAnalytics] = useState([]);

  const getTasks = (params = {}) => {
    setLoading(true);

    return client.get('/tasks', { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setSorter(updatedSorterFromParams(params));
      setFilters(updatedFiltersFromParams(params));
      setQueries(updatedQueriesFromParams(params));

      setTasks([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const getAllTasks = (params = {}) => {
    setLoading(true);

    return client.get('/tasks/all', { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setSorter(updatedSorterFromParams(params));
      setFilters(updatedFiltersFromParams(params));
      setQueries(updatedQueriesFromParams(params));

      setTasks([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const updateTasks = (newPagination, newFilters, newSorter, extra) => {
    const finalQueries = extra.action === 'queries' ? extra.queries : queries;
    const searchParams = searchParamsFromTableStatus(
      newPagination, newFilters, newSorter, extra, finalQueries
    );

    getTasks(searchParams);

    return searchParams;
  };

  const updateAllTasks = (newPagination, newFilters, newSorter, extra) => {
    const finalQueries = extra.action === 'queries' ? extra.queries : queries;
    const searchParams = searchParamsFromTableStatus(
      newPagination, newFilters, newSorter, extra, finalQueries
    );

    getAllTasks(searchParams);

    return searchParams;
  };

  const updateTasksLocally = (data) => {
    const updatedTasks = tasks.map((task) => {
      const responseTask = data.find((c) => c.id === task.id);

      if (responseTask) {
        return { ...task, ...responseTask };
      }

      return task;
    });

    setTasks(updatedTasks);
  };

  const assignToMe = (taskId) => (
    client.patch('/tasks/assign', { data: { taskId } }).then((response) => {
      updateTasksLocally(response.data);

      if (response.data[0]) {
        message.success('The task was reassigned to you');
      }

      return response;
    })
  );

  const assignTo = (taskId, assigneeId) => (
    client.patch(`/tasks/assign/${assigneeId}`, { data: { taskId } }).then((response) => {
      updateTasksLocally(response.data);

      if (response.data[0]) {
        if (response.data[0].assignee) {
          message.success(`The task was reassigned to ${response.data[0].assignee.name}`);
        } else {
          message.success('The assignee was removed for selected tasks');
        }
      }

      return response;
    })
  );

  const completeBulk = (taskId) => (
    client.patch('/tasks/complete', { data: { taskId } }).then((response) => {
      if (response.data[0]) {
        message.success('The tasks were completed');
      }

      return response;
    })
  );

  const cancelBulk = (taskId) => (
    client.patch('/tasks/cancel', { data: { taskId } }).then((response) => {
      if (response.data[0]) {
        message.success('The tasks were cancelled');
      }

      return response;
    })
  );

  const updateTask = (taskId, task) => (
    client.patch(`/tasks/${taskId}`, { data: task }).then((response) => {
      // claimTasks and setClaimTasks are fuctions in ClaimContext.
      // it needs to validate existence because the order of the contexts is defined by the client
      if (claimTasks) {
        const updatedTasks = claimTasks.map((claimTask) => (
          claimTask.id === response.data.id ? response.data : claimTask
        ));

        setClaimTasks(updatedTasks);
      }

      return response;
    })
  );

  const complete = (taskId) => (
    client.patch(`/tasks/${taskId}/complete`)
  );

  const cancel = (taskId) => (
    client.patch(`/tasks/${taskId}/cancel`)
  );

  const getTaskHistory = (taskId) => {
    setHistoryLoading(true);

    return client.get(`/tasks/${taskId}/history`).then((response) => {
      setHistory([...response.data]);

      return response;
    }).finally(() => {
      setHistoryLoading(false);
    });
  };

  const getTaskAnalytics = (params = {}) => {
    setLoading(true);

    return client.get('/tasks/analytics', { params }).then((response) => {
      setTaskAnalytics([...response.data]);

      return response;
    }).finally(() => {
      setHistoryLoading(false);
    });
  };

  const value = {
    tasks,
    getTasks,
    getAllTasks,
    updateTasks,
    updateAllTasks,
    loading,
    pagination,
    filters,
    sorter,
    queries,
    setFilters,
    assignToMe,
    assignTo,
    completeBulk,
    cancelBulk,
    updateTask,
    complete,
    cancel,
    historyLoading,
    getTaskHistory,
    history,
    taskAnalytics,
    getTaskAnalytics
  };

  return (
    <TasksContext.Provider value={value}>
      {children}
    </TasksContext.Provider>
  );
};

TasksContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default TasksContextProvider;
