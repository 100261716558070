import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from '../utils/dates';

export const DateTimeFormatter = ({ value }) => (
  <time dateTime={value}>
    { formatDateTime(value) }
  </time>
);

DateTimeFormatter.defaultProps = {
  value: ''
};

DateTimeFormatter.propTypes = {
  value: PropTypes.string
};

export default DateTimeFormatter;
