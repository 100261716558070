import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';

export const CurrentAccountContext = createContext();

export const CurrentAccountContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [claimStructure, setClaimStructure] = useState({});
  const [hasUploads, setHasUploads] = useState(false);
  const [account, setAccount] = useState({});

  useEffect(() => {
    if (!account.claimStructure) {
      return;
    }

    setClaimStructure({ ...account.claimStructure });
    setHasUploads(!!account.claimStructure.createTabs.find((tab) => tab.key === 'uploads'));
  }, [account]);

  const getCurrentAccount = () => (
    client.get('/accounts/current').then((response) => {
      setAccount(response.data);

      return response;
    })
  );

  const switchAccount = (newAccount) => {
    setAccount(newAccount);

    return newAccount.claimStructure;
  };

  const value = {
    claimStructure,
    account,
    hasUploads,
    getCurrentAccount,
    switchAccount
  };

  return (
    <CurrentAccountContext.Provider value={value}>
      {children}
    </CurrentAccountContext.Provider>
  );
};

CurrentAccountContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CurrentAccountContextProvider;
