import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../utils/dates';

export const DateFormatter = ({ value }) => (
  <time dateTime={value}>
    { formatDate(value) }
  </time>
);

DateFormatter.defaultProps = {
  value: ''
};

DateFormatter.propTypes = {
  value: PropTypes.any
};

export default DateFormatter;
