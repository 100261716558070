import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { FormItemWithErrors } from './FormItemWithErrors';

export const SelectWithErrors = ({
  name,
  errors,
  label,
  updateModel,
  placeholder,
  showSearch,
  options,
  optionKey,
  optionValue,
  disabled,
  blank,
  modelName
}) => {
  const onChange = (value) => {
    updateModel({ [name]: value });
  };

  return (
    <FormItemWithErrors label={label} name={name} errors={errors} modelName={modelName}>
      <Select
        showSearch={showSearch}
        optionFilterProp="children"
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      >
        {
          blank && (
            <Select.Option value="">
              {blank}
            </Select.Option>
          )
        }
        {
          options.map((option) => (
            <Select.Option value={option[optionKey]} key={option[optionKey]}>
              {option[optionValue]}
            </Select.Option>
          ))
        }
      </Select>
    </FormItemWithErrors>
  );
};

SelectWithErrors.defaultProps = {
  errors: {},
  label: null,
  placeholder: '',
  updateModel: () => {},
  showSearch: false,
  options: [],
  optionKey: 'key',
  optionValue: 'value',
  disabled: false,
  blank: '',
  modelName: ''
};

SelectWithErrors.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  updateModel: PropTypes.func,
  showSearch: PropTypes.bool,
  options: PropTypes.array,
  optionKey: PropTypes.string,
  optionValue: PropTypes.string,
  disabled: PropTypes.bool,
  blank: PropTypes.string,
  modelName: PropTypes.string
};

export default SelectWithErrors;
