import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from '../utils/I18n';

export const ClubDescription = ({ model }) => (
  <Fragment>
    <dt>
      {t('activerecord.models.federation.one')}
    </dt>
    <dd>
      {model.federation && model.federation.name}
    </dd>
    <dt>
      {t('activerecord.models.group.one')}
    </dt>
    <dd>
      {model.group && model.group.name}
    </dd>
    <dt>
      {t('activerecord.models.club.one')}
    </dt>
    <dd>
      {model.club && model.club.name}
    </dd>
  </Fragment>
);

ClubDescription.propTypes = {
  model: PropTypes.object.isRequired
};

export default ClubDescription;
