import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';
import { DEFAULT_PAGINATION, updatedPaginationFromResponse } from '../utils/tables';

export const FederationsContext = createContext();

export const FederationsContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  // const { account } = useContext(AccountContext);
  const [federations, setFederations] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [loading, setLoading] = useState(false);
  const [federationLoading, setFederationLoading] = useState(false);

  const getFederations = (params = {}) => {
    setLoading(true);

    return client.get('federations', { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setFederations([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const createFederation = (federation) => {
    setFederationLoading(true);

    return client.post('federations', { data: { ...federation } }).then((response) => {
      setFederations([...federations, response.data]);

      return response;
    }).finally(() => {
      setFederationLoading(false);
    });
  };

  const value = {
    federations,
    getFederations,
    createFederation,
    loading,
    pagination,
    federationLoading
  };

  return (
    <FederationsContext.Provider value={value}>
      {children}
    </FederationsContext.Provider>
  );
};

FederationsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FederationsContextProvider;
