import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { ApiContext } from './ApiContext';
import { t } from '../utils/I18n';
import { isUnprocessableEntity } from '../utils/apiErrorHandling';

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [user, setUser] = useState({});
  const [hasPermissions, setPermissions] = useState(false);
  const [hasClaims, setClaims] = useState(false);

  useEffect(() => {
    setPermissions(user.role !== 'claimant' && user.role !== 'admin' && user.role !== 'gowgates');
    setClaims(user.role === 'claimant');
  }, [user.role]);

  const getUser = (id) => (
    client.get(`/users/${id}`).then((response) => {
      setUser({ ...response.data });

      return response;
    })
  );

  const handleError = (error) => {
    if (isUnprocessableEntity(error)) {
      setUser({ ...error.response.data });
      message.error(t('globals.errorsInForm'));
    }

    throw error;
  };

  const updateUser = (values) => (
    client.put(`/users/${user.id}`, { data: { user: values } }).then((response) => {
      setUser({ ...response.data });

      return response;
    }).catch(handleError)
  );

  const createUser = ((values) => (
    client.post('/users', { data: { user: values } }).then((response) => {
      setUser({ ...response.data });

      return response;
    }).catch(handleError)
  ));

  const confirmUser = () => (
    client.patch(`users/${user.id}/confirm`).then((response) => {
      setUser({ ...response.data });

      return response;
    })
  );

  const unlockUser = () => (
    client.patch(`users/${user.id}/unlock`).then((response) => {
      setUser({ ...response.data });

      return response;
    })
  );

  const resetPassword = () => (
    client.patch(`users/${user.id}/reset_password`)
  );

  const disableOtp = () => (
    client.patch(`users/${user.id}/disable_otp`).then((response) => {
      setUser({ ...response.data });

      return response;
    })
  );

  const blockUser = () => (
    client.patch(`users/${user.id}/block`).then((response) => {
      setUser({ ...response.data });

      return response;
    })
  );

  const unblockUser = () => (
    client.patch(`users/${user.id}/unblock`).then((response) => {
      setUser({ ...response.data });

      return response;
    })
  );

  const value = {
    user,
    getUser,
    createUser,
    updateUser,
    setUser,
    confirmUser,
    unlockUser,
    resetPassword,
    disableOtp,
    blockUser,
    unblockUser,
    hasPermissions,
    hasClaims
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default UserContextProvider;
