import React from 'react';
import PropTypes from 'prop-types';
import { DatePickerWithErrors } from './DatePickerWithErrors';
import { DateTimePickerWithErrors } from './DateTimePickerWithErrors';
import { FilesUploaderWithErrors } from './FilesUploaderWithErrors';
import { FileUploaderWithErrors } from './FileUploaderWithErrors';
import { SelectWithErrors } from './SelectWithErrors';
import { TextAreaWithErrors } from './TextAreaWithErrors';
import { Club } from './Club';
import { Trip } from './Trip';
import { InputWithErrors } from './InputWithErrors';

export const DynamicFieldWithErrors = ({ model, field, updateModel, form, hideLabel }) => {
  const components = {
    club: Club,
    trip: Trip
  };

  const label = hideLabel ? '' : field.label;

  if (field.type === 'date') {
    return (
      <DatePickerWithErrors
        label={label}
        name={field.key}
        errors={model.errors}
        updateModel={updateModel}
        constraints={field.constraints}
      />
    );
  }

  if (field.type === 'datetime') {
    return (
      <DateTimePickerWithErrors
        label={label}
        name={field.key}
        errors={model.errors}
        updateModel={updateModel}
      />
    );
  }

  if (field.type === 'text') {
    return (
      <TextAreaWithErrors
        label={label}
        name={field.key}
        errors={model.errors}
        updateModel={updateModel}
      />
    );
  }

  if (field.type === 'select') {
    return (
      <SelectWithErrors
        label={label}
        name={field.key}
        errors={model.errors}
        updateModel={updateModel}
        showSearch={field.showSearch}
        options={field.options}
      />
    );
  }

  if (field.type === 'money') {
    return (
      <InputWithErrors
        label={label}
        name={field.key}
        errors={model.errors}
        updateModel={updateModel}
        version="money"
      />
    );
  }

  if (field.type === 'phone') {
    return (
      <InputWithErrors
        label={label}
        name={field.key}
        errors={model.errors}
        updateModel={updateModel}
        version="phone"
      />
    );
  }

  if (field.type === 'percent') {
    return (
      <InputWithErrors
        label={label}
        name={field.key}
        errors={model.errors}
        updateModel={updateModel}
        version="percent"
      />
    );
  }

  if (field.type === 'file') {
    return (
      <FileUploaderWithErrors
        label={label}
        name={field.key}
        errors={model.errors}
        form={form}
        model={model}
      />
    );
  }

  if (field.type === 'files') {
    return (
      <FilesUploaderWithErrors
        label={label}
        name={field.key}
        errors={model.errors}
        form={form}
        model={model}
      />
    );
  }

  if (field.type === 'component') {
    const DynamicComponent = components[field.componentType];
    return <DynamicComponent model={model} field={field} updateModel={updateModel} form={form} />;
  }

  return (
    <InputWithErrors
      label={label}
      name={field.key}
      errors={model.errors}
      updateModel={updateModel}
    />
  );
};

DynamicFieldWithErrors.defaultProps = {
  updateModel: () => {},
  hideLabel: false
};

DynamicFieldWithErrors.propTypes = {
  model: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  updateModel: PropTypes.func,
  form: PropTypes.object.isRequired,
  hideLabel: PropTypes.bool
};

export default DynamicFieldWithErrors;
