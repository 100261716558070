import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from '../utils/I18n';
import { DateFormatter } from '../formatters/DateFormatter';

export const TripDescription = ({ model }) => (
  <Fragment>
    <dt className="ant-col ant-col-8">
      {t('activerecord.attributes.trip.code')}
    </dt>
    <dd className="ant-col ant-col-16">
      {model.trip && model.trip.code}
    </dd>

    <dt className="ant-col ant-col-8">
      {t('activerecord.attributes.trip.award')}
    </dt>
    <dd className="ant-col ant-col-16">
      {model.trip && model.trip.award}
    </dd>

    <dt className="ant-col ant-col-8">
      {t('activerecord.attributes.trip.destination')}
    </dt>
    <dd className="ant-col ant-col-16">
      {model.trip && model.trip.destination}
    </dd>

    <dt className="ant-col ant-col-8">
      {t('activerecord.attributes.trip.departedAt')}
    </dt>
    <dd className="ant-col ant-col-16">
      {model.trip && <DateFormatter value={model.trip.departedAt} />}
    </dd>

    <dt className="ant-col ant-col-8">
      {t('activerecord.attributes.trip.arrivedAt')}
    </dt>
    <dd className="ant-col ant-col-16">
      {model.trip && <DateFormatter value={model.trip.arrivedAt} />}
    </dd>
  </Fragment>
);

TripDescription.propTypes = {
  model: PropTypes.object.isRequired
};

export default TripDescription;
