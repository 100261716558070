import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Typography, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { UserAvatar } from './UserAvatar';
import { formatDateTime } from '../utils/dates';

export const CommentEntry = ({ comment, subTitle, currentUser, onDelete }) => {
  const dropdownMenu = () => (
    <Menu>
      <li>
        <div
          role="menuitem"
          onClick={() => onDelete(comment)}
          onKeyUp={() => onDelete(comment)}
          tabIndex={-1}
        >
          Delete comment
        </div>
      </li>
    </Menu>
  );

  const commentClasses = () => (
    `comment ${comment.user.id === currentUser.id ? 'outgoing' : 'ingoing'}`
  );

  return (
    <article className={commentClasses()}>
      <header className="mb-2">
        <div>
          <UserAvatar className="mr-3" user={comment.user} />
          {comment.user.name}
          <span> — </span>

          <Typography.Text type="secondary">
            {formatDateTime(comment.createdAt)}
          </Typography.Text>

          {
            comment.permissions.canDestroy && (
              <Dropdown
                trigger={['click']}
                placement="bottomRight"
                overlay={dropdownMenu}
                className="ml-2"
              >
                <MoreOutlined />
              </Dropdown>
            )
          }
        </div>

        {comment.commentableType === 'Item' && subTitle}
      </header>

      {/* eslint-disable-next-line react/no-danger */}
      <div className="comment-text" dangerouslySetInnerHTML={{ __html: comment.text }} />

      {
        comment.documents.length > 0 && (
          <div className="mt-3">
            <h4>Attachments:</h4>
            {
              comment.documents.map((document) => (
                <p key={document.id}>
                  <a href={document.file.url} target="_blank" rel="noopener noreferrer">
                    {document.file.name}
                  </a>
                </p>
              ))
            }
          </div>
        )
      }
    </article>
  );
};

CommentEntry.defaultProps = {
  subTitle: null,
  onDelete: () => {},
  currentUser: {
    id: -1
  }
};

CommentEntry.propTypes = {
  comment: PropTypes.object.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  subTitle: PropTypes.node,
  onDelete: PropTypes.func
};

export default CommentEntry;
