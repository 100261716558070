import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Form } from 'antd';
import { AppContext } from '../../contexts/AppContext';
import { FormItemWithErrors } from './FormItemWithErrors';

export const InputWithErrors = ({
  name,
  errors,
  label,
  placeholder,
  updateModel,
  version,
  type,
  modelName,
  disabled,
  addonBefore,
  addonAfter
}) => {
  let timeout;
  const [finalType, setFinalType] = useState('text');
  const { countries, getCountries, currencies, getCurrencies } = useContext(AppContext);

  useEffect(() => {
    if (version === 'phone') {
      getCountries();
    }
  }, [version, getCountries]);

  useEffect(() => {
    if (version === 'money') {
      getCurrencies();
    }
  }, [version, getCurrencies]);

  const onChange = (event) => {
    const { value } = event.target;

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      updateModel({ [name]: value });
    }, 500);
  };

  const onChangeSelect = (field, value) => {
    updateModel({ [field]: value });
  };

  useEffect(() => {
    if (version === 'money' || version === 'percent') {
      setFinalType('number');
    } else if (version === 'phone') {
      setFinalType('tel');
    } else {
      setFinalType(type || 'text');
    }
  }, [setFinalType, type, version]);

  const appliedAddonBefore = () => {
    if (addonBefore) {
      return addonBefore;
    }

    if (version === 'money') {
      return (
        <Form.Item name={`${name}Currency`} noStyle>
          <Select
            showSearch
            optionLabelProp="label"
            optionFilterProp="children"
            dropdownMatchSelectWidth={300}
            onChange={(value) => onChangeSelect(`${name}Currency`, value)}
          >
            {
              currencies.map((currency) => (
                <Select.Option value={currency.code} label={currency.code} key={currency.code}>
                  {currency.name}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      );
    }

    if (version === 'phone') {
      return (
        <Form.Item name={`${name}Country`} noStyle>
          <Select
            showSearch
            optionLabelProp="label"
            optionFilterProp="children"
            dropdownMatchSelectWidth={300}
            onChange={(value) => onChangeSelect(`${name}Country`, value)}
          >
            {
              countries.map((country) => (
                <Select.Option value={country.key} label={`+${country.code}`} key={country.key}>
                  {`+${country.code} - ${country.value}`}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      );
    }

    return null;
  };

  const appliedAddonAfter = () => {
    if (addonAfter) {
      return addonAfter;
    }

    if (version === 'percent') {
      return '%';
    }

    return null;
  };

  return (
    <FormItemWithErrors label={label} name={name} errors={errors} modelName={modelName}>
      <Input
        autoComplete="off"
        onChange={onChange}
        placeholder={placeholder}
        addonBefore={appliedAddonBefore()}
        addonAfter={appliedAddonAfter()}
        type={finalType}
        disabled={disabled}
      />
    </FormItemWithErrors>
  );
};

InputWithErrors.defaultProps = {
  errors: {},
  label: null,
  placeholder: null,
  updateModel: () => {},
  version: null,
  type: 'text',
  modelName: '',
  disabled: false,
  addonBefore: null,
  addonAfter: null
};

InputWithErrors.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  updateModel: PropTypes.func,
  version: PropTypes.string,
  type: PropTypes.string,
  modelName: PropTypes.string,
  disabled: PropTypes.bool,
  addonBefore: PropTypes.node,
  addonAfter: PropTypes.node
};

export default InputWithErrors;
