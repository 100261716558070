import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from './ApiContext';
import {
  DEFAULT_PAGINATION,
  updatedPaginationFromResponse,
  searchParamsFromTableStatus,
  updatedSorterFromParams,
  updatedFiltersFromParams,
  updatedQueriesFromParams
} from '../utils/tables';

export const TripsContext = createContext();

export const TripsContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [trips, setTrips] = useState([]);
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});
  const [queries, setQueries] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [loading, setLoading] = useState(false);

  const getTrips = (params = {}) => {
    setLoading(true);

    return client.get('/trips', { params }).then((response) => {
      setPagination(updatedPaginationFromResponse(pagination, response));
      setSorter(updatedSorterFromParams(params));
      setFilters(updatedFiltersFromParams(params));
      setQueries(updatedQueriesFromParams(params));

      setTrips([...response.data]);

      return response;
    }).finally(() => {
      setLoading(false);
    });
  };

  const updateTrips = (newPagination, newFilters, newSorter, extra) => {
    const finalQueries = extra.action === 'queries' ? extra.queries : queries;
    const searchParams = searchParamsFromTableStatus(
      newPagination, newFilters, newSorter, extra, finalQueries
    );

    getTrips(searchParams);

    return searchParams;
  };

  const uploadTrips = (values) => {
    setLoading(true);

    return client.post('/trips/upload', { data: { trip: values } }).finally(() => {
      setLoading(false);
    });
  };

  const value = {
    trips,
    getTrips,
    updateTrips,
    loading,
    pagination,
    filters,
    sorter,
    queries,
    setFilters,
    uploadTrips
  };

  return (
    <TripsContext.Provider value={value}>
      {children}
    </TripsContext.Provider>
  );
};

TripsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default TripsContextProvider;
