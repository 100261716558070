import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Button, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormItemWithErrors } from './FormItemWithErrors';
import ExistingFile from './ExistingFile';
import { ALLOWED_MIME_TYPES } from '../../utils/constants';

export const FilesUploaderWithErrors = ({
  name,
  errors,
  label,
  form,
  model,
  reload,
  modelName
}) => {
  const [fileList, setFileList] = useState([]);
  const [existingDocuments, setExistingDocuments] = useState([]);

  const setDocument = (file) => {
    const files = existingDocuments.map((f) => {
      if (f.id === file.id) {
        return file;
      }

      return f;
    });

    setExistingDocuments(files);
  };

  // Hack to force reseting file list. When reload changes the files are cleared
  useEffect(() => {
    setFileList([]);
    setExistingDocuments([]);
  }, [reload]);

  useEffect(() => {
    if (model[name] && model[name].length > 0) {
      setExistingDocuments(model[name]);
    }
  }, [model, name, setFileList]);

  useEffect(() => {
    const filesForAttributes = fileList.map((f) => ({ file: f }));

    form.setFieldsValue({
      ...form.getFieldsValue(),
      [`${name}Attributes`]: [...filesForAttributes, ...existingDocuments]
    });
  }, [fileList, existingDocuments, form, name]);

  const onRemove = (file) => {
    setFileList(fileList.filter((f) => f !== file));
  };

  const beforeUpload = (file) => {
    setFileList([...fileList, file]);
    return false;
  };

  return (
    <div className="files-uploader">
      <div className="d-none">
        <FormItemWithErrors name={`${name}Attributes`} className="d-none">
          <Input type="hidden" />
        </FormItemWithErrors>
      </div>

      <FormItemWithErrors
        label={label}
        name={name}
        errors={errors}
      >
        <Upload
          onRemove={onRemove}
          beforeUpload={beforeUpload}
          fileList={fileList}
          modelName={modelName}
          accept={ALLOWED_MIME_TYPES.join(',')}
        >
          <Button>
            <UploadOutlined />
            Add File
          </Button>
        </Upload>
      </FormItemWithErrors>

      <div className="ant-upload-list ant-upload-list-text">
        {
          existingDocuments.map((document) => (
            <ExistingFile document={document} setDocument={setDocument} key={document.id} />
          ))
        }
      </div>
    </div>
  );
};

FilesUploaderWithErrors.defaultProps = {
  errors: {},
  label: null,
  reload: 0,
  modelName: ''
};

FilesUploaderWithErrors.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  model: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  reload: PropTypes.number,
  modelName: PropTypes.string
};

export default FilesUploaderWithErrors;
