import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { ApiContext } from './ApiContext';
import { t } from '../utils/I18n';
import { isUnprocessableEntity } from '../utils/apiErrorHandling';

export const FederationContext = createContext();

export const FederationContextProvider = ({ children }) => {
  const { client } = useContext(ApiContext);
  const [federation, setFederation] = useState({});
  const [federationLoading, setFederationLoading] = useState(false);
  const [verifiers, setVerifiers] = useState([]);
  const [verifiersLoading, setVerifiersLoading] = useState(false);

  const getFederation = (id) => {
    setFederationLoading(true);

    return client.get(`/federations/${id}`).then((response) => {
      setFederation({ ...response.data });

      return response;
    }).finally(() => {
      setFederationLoading(false);
    });
  };

  const getVerifiers = (id) => {
    setVerifiersLoading(true);

    return client.get(`/federations/${id}/verifiers`).then((response) => {
      setVerifiers(response.data);

      return response;
    }).finally(() => {
      setVerifiersLoading(false);
    });
  };

  const handleError = (error) => {
    if (isUnprocessableEntity(error)) {
      setFederation({ ...error.response.data });
      message.error(t('globals.errorsInForm'));
    }

    throw error;
  };

  const updateFederation = (values) => {
    setFederationLoading(true);

    return client.put(`/federations/${federation.id}`, { data: { federation: values } }).then((response) => {
      setFederation({ ...response.data });
      message.success('Federation updated');

      return response;
    }).catch(handleError).finally(() => {
      setFederationLoading(false);
    });
  };

  const value = {
    getFederation,
    federation,
    updateFederation,
    getVerifiers,
    verifiers,
    federationLoading,
    verifiersLoading
  };

  return (
    <FederationContext.Provider value={value}>
      {children}
    </FederationContext.Provider>
  );
};

FederationContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FederationContextProvider;
